.background-color-brand {
  background-color: #1699FF !important;
}

.background-color-dark {
  background-color: #161d20;
}

.background-color-steelblue {
  background-color: steelblue;
}

.file_input {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: steelblue;
  color: white;
}

.max-height-85vh {
  max-height: calc(85vh - 3.5rem);
}

.max-vh-60 {
  max-height: 60vh;
  overflow: auto;
}

.not-selectable {
  user-select: none;
}

.flat-top {
  border-top-left-radius: 0; 
  border-bottom-left-radius: 0
}

.selected {
  background-color: rgb(22, 153, 255);
}

.w-90 {
  max-width: 90%;
  width: 100%;
}